document.addEventListener('turbo:load', () => {
  $('.admin-calllog .js-tablesorter')
    .tablesorter({
      theme: 'bootstrap',
      widthFixed: true,
      widgets: ['filter', 'zebra'],
      widgetOptions: {
        zebra: ['even', 'odd'],
        columns: ['primary', 'secondary', 'tertiary'],
        filter_reset: '.reset',
        filter_cssFilter: [
          'form-control',
          'form-select',
          'form-select',
          'form-select',
          'form-select',
          'form-control',
          'form-control',
          'form-control',
          'form-control',
          'form-control',
          'form-control',
          'form-control',
          'form-control'
        ]
      }
    })
    .tablesorterPager({
      container: $('.ts-pager'),
      cssGoto: '.pagenum',
      removeRows: false,
      output: '{startRow} - {endRow} / {filteredRows} ({totalRows})'
    });
});