// rails webpacker:install:stimulus
// yarn add tributejs

import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ] // Located on the elements of the POST view

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      requireLeadingSpace: false,
      lookup: 'name',
      menuItemLimit: 5,
      values: this.fetchUsers,
      menuItemTemplate: function (item) {
        return `
          <img src=${item.original.image} class="tw-inline-block tw-mr-1 tw-w-8">
          <b>${item.string}</b>
        `
      },
    })
    this.tribute.attach(this.fieldTarget) // Connect the TRIBUTE instance to the element
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this) // After clicking back a character, you will go out @:
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced) // Add events to target elements, when completed
  }

  fetchUsers(text, callback) {
    fetch(`/mentions.json?query=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]))
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position - (endPos - startPos), position])
    this.editor.deleteInDirection("backward")
  }

  replaced(e) {
    let mention = e.detail.item.original // Get data in an event
    //  Create Trix.attachment, then call the Inserttachment method to insert HTML
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ") // Insert a space, see the TRIX API
  }
}