import '../../assets/stylesheets/application.scss'

import '../src/jquery.js'
// Select2
import 'select2';
import 'select2/dist/css/select2' // select2 の CSS
import 'select2/dist/js/i18n/ja' // エラーメッセージの日本語化

$.fn.select2.defaults.set('allowClear', true)    // 空にできるようにする
$.fn.select2.defaults.set('placeholder', '')     // allowClear だけだとエラーになるため
$.fn.select2.defaults.set('width', '100%')       // tdタグの中に入れたときにだんだん大きくなっていくのを防ぐ

// Entry point for the build script in your package.json
import '../controllers'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@fortawesome/fontawesome-free/js/all'

// Bootstrap
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

//import '../channels'
import '../src/cocoon'
import '../../../vendor/plugins/fixed-table-header/fixed-table-header'

// Scroll top
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()
import '../src/scrolltop'

// TableSorter
import '../../../vendor/plugins/tablesorter/js/jquery.tablesorter.min'
import '../../../vendor/plugins/tablesorter/js/jquery.tablesorter.widgets.min'
import '../../../vendor/plugins/tablesorter/js/extras/jquery.tablesorter.pager.min'

// rails-ujs
import Rails from 'rails-ujs'
Rails.start();

import "@hotwired/turbo-rails"
// import { Turbo } from "@hotwired/turbo-rails"
// Turbo.session.drive = false

import 'trix'
import '@rails/actiontext'
import '../src/richtext'
import 'js-toc'

import '../pages/admin_calllogs'
import '../pages/wikis'

document.addEventListener('turbo:load', () => {
  // Ajax spinner
  $('body').bind('ajax:before', function(){
    $('main').addClass('loading');
    $('.loader').show();
  })
    .bind('ajax:success ajax:error', function() {
      $('main').removeClass('loading');
      $('.loader').hide();
    });

  // Auto grow textarea
  function autoGrowTextarea() {
    const autoGrowTextareas = document.getElementsByClassName("js-autogrow-textarea");
    for (let i = 0; i < autoGrowTextareas.length; i++) {
      autoGrowTextareas[i].setAttribute("style", "height:" + (autoGrowTextareas[i].scrollHeight) + "px;overflow-y:hidden;");
      autoGrowTextareas[i].addEventListener("input", autoGrowOnInput, false);
    }
  }
  function autoGrowOnInput() {
    this.style.height = "auto";
    this.style.height = (this.scrollHeight) + "px";
  }
  autoGrowTextarea()

  $('.js-fixed-table-header').fixedTableHeader();

  // Select2
  $('.js-select2').select2();
  $('.js-select2-tags').select2({tags: true});
  // Focus on search bar
  $(document).on('select2:open', () => {
    document.querySelector('.select2-container--open .select2-search__field').focus()
  })
  $('body').on('cocoon:after-insert', function () {
    $('.js-select2').select2();
    $('.js-select2-tags').select2({tags: true});
  })
})

window.addEventListener('load', () => {
  $('body').has('.user-login').addClass('login-background-image');
})
